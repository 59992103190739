@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=cyrillic');

@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

p {
  margin: 0;
}

[type='range'] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
  margin: 0;
  padding: 0;
  width: 12.5em;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  outline: 0;
}
[type='range'],
[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}
[type='range']::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
.js [type='range']::-webkit-slider-runnable-track {
  background: linear-gradient(#95a, #95a) 0 / var(--sx) 100% no-repeat #ccc;
}
[type='range']::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
[type='range']::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
[type='range']::-moz-range-progress {
  height: 0.25em;
  background: #95a;
}
[type='range']::-ms-fill-lower {
  height: 0.25em;
  background: #95a;
}
[type='range']::-webkit-slider-thumb {
  margin-top: -0.625em;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}
[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}
[type='range']::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}

[type='range']:focus::-webkit-slider-thumb {
  border-color: #000;
}
[type='range']:focus::-moz-range-thumb {
  border-color: #000;
}
[type='range']:focus::-ms-thumb {
  border-color: #000;
}
[type='range']::-ms-tooltip {
  display: none;
}
