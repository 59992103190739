.App {
  padding: 40px 0;

  background: #b5ae94;
  background: #e7e1d1;
  background: #27254a;
  background: #321d78;
  background: #422e6b;
  background: #47292c;
  background: #18004b;
  background: #a2a1af;
  background: #bab6c6;
  background: #042123;

  min-height: 100vh;
}

.App.black-theme {
  background: #000;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.settings::-webkit-scrollbar {
  display: none;
}

.settings {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  height: 100vh;
  /* width: 20%; */

  display: flex;
  flex-direction: column;
  background: #fff;

  /* padding: 8px; */
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  overflow: auto;
}

.settings.sidebar--show {
  transform: translateX(0);
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
}

.settings-toggle {
  width: 28px;
  height: 28px;
  line-height: 28px;
  position: fixed;
  top: 8px;
  right: 4px;
  z-index: 99999;
  border-radius: 50%;
  /* color: #fff; */
  /* background: #000; */
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  background: #fff;
}

.table-header,
.table-footer {
  display: flex;
  justify-content: space-between;
}

.table-header {
  margin-bottom: 24px;
}

.table-footer {
  margin-top: 24px;
}

.table-header__settings {
  display: flex;
  overflow-x: auto;
}

.temperature {
  width: 33.3%;
}

/*phone-only*/
@media (max-width: 599px) {
  .App {
    width: 100vw;
    overflow: auto;
    color: #fff;
    background: #000;
    padding: 0;
    overflow-x: hidden;
  }

  .App .table {
    width: 1600px;
    width: 300vw;
    padding: 0;
  }

  .container {
    width: 100%;
    overflow-x: scroll;
  }

  .table-header,
  .table-footer {
    /* padding: 8px; */
    /* overflow-x: scroll; */
    flex-wrap: wrap;
  }

  .table-header__settings {
    padding: 8px;
  }

  .temperature {
    padding: 8px;
    width: 100%;
  }

  .media--hide {
    display: none;
  }
}
