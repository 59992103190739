@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=cyrillic);
@import url(https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700);
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

p {
  margin: 0;
}

[type='range'] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
  margin: 0;
  padding: 0;
  width: 12.5em;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  outline: 0;
}
[type='range'],
[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}
[type='range']::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
.js [type='range']::-webkit-slider-runnable-track {
  background: linear-gradient(#95a, #95a) 0 / var(--sx) 100% no-repeat #ccc;
}
[type='range']::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
[type='range']::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}
[type='range']::-moz-range-progress {
  height: 0.25em;
  background: #95a;
}
[type='range']::-ms-fill-lower {
  height: 0.25em;
  background: #95a;
}
[type='range']::-webkit-slider-thumb {
  margin-top: -0.625em;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}
[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}
[type='range']::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #aaa;
}

[type='range']:focus::-webkit-slider-thumb {
  border-color: #000;
}
[type='range']:focus::-moz-range-thumb {
  border-color: #000;
}
[type='range']:focus::-ms-thumb {
  border-color: #000;
}
[type='range']::-ms-tooltip {
  display: none;
}

.App {
  padding: 40px 0;

  background: #b5ae94;
  background: #e7e1d1;
  background: #27254a;
  background: #321d78;
  background: #422e6b;
  background: #47292c;
  background: #18004b;
  background: #a2a1af;
  background: #bab6c6;
  background: #042123;

  min-height: 100vh;
}

.App.black-theme {
  background: #000;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.settings::-webkit-scrollbar {
  display: none;
}

.settings {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  height: 100vh;
  /* width: 20%; */

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #fff;

  /* padding: 8px; */
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  overflow: auto;
}

.settings.sidebar--show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
}

.settings-toggle {
  width: 28px;
  height: 28px;
  line-height: 28px;
  position: fixed;
  top: 8px;
  right: 4px;
  z-index: 99999;
  border-radius: 50%;
  /* color: #fff; */
  /* background: #000; */
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  background: #fff;
}

.table-header,
.table-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.table-header {
  margin-bottom: 24px;
}

.table-footer {
  margin-top: 24px;
}

.table-header__settings {
  display: -webkit-flex;
  display: flex;
  overflow-x: auto;
}

.temperature {
  width: 33.3%;
}

/*phone-only*/
@media (max-width: 599px) {
  .App {
    width: 100vw;
    overflow: auto;
    color: #fff;
    background: #000;
    padding: 0;
    overflow-x: hidden;
  }

  .App .table {
    width: 1600px;
    width: 300vw;
    padding: 0;
  }

  .container {
    width: 100%;
    overflow-x: scroll;
  }

  .table-header,
  .table-footer {
    /* padding: 8px; */
    /* overflow-x: scroll; */
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .table-header__settings {
    padding: 8px;
  }

  .temperature {
    padding: 8px;
    width: 100%;
  }

  .media--hide {
    display: none;
  }
}

.table {
  /* min-width: 3000px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;

  display: grid;
  /* grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto; */
  grid-template-columns: repeat(18, 1fr);
  /* grid-column-gap: 2px; */
  /* grid-row-gap: 4px; */
  /* grid-template-rows: 25% 100px auto; */

  /* padding-top: 80px; */
  font-family: Oswald, sans-serif;
}

.table-kelvins {
  grid-column-start: 14;
  grid-column-end: span 3;
  grid-row-start: 1;
  font-size: 14px;
  color: #aaa;
}

.table-period {
  color: #aaa;
  text-align: right;
  padding: 4px 8px;
}

.table-period--1 {
  grid-row-start: 1;
}
.table-period--2 {
  grid-row-start: 2;
}
.table-period--3 {
  grid-row-start: 3;
}
.table-period--4 {
  grid-row-start: 4;
}
.table-period--5 {
  grid-row-start: 5;
}
.table-period--6 {
  grid-row-start: 6;
}
.table-period--7 {
  grid-row-start: 7;
}

.table-serie {
  font-size: 12px;
  color: #aaa;
  padding: 4px;
}

.table-align--right {
  text-align: right;
}

.element--1 {
  grid-column-start: 1;
}
.element--2 {
  grid-column-start: 18;
}
.element--5 {
  grid-column-start: 13;
}
.element--13 {
  grid-column-start: 13;
}
.element--21 {
  grid-column-start: 3;
}
.element--39 {
  grid-column-start: 3;
}

/* .element--57 {
  grid-column-start: 3;
  grid-row-start: 9;
} */
.element--58 {
  grid-column-start: 4;
  grid-row-start: 9;
}
.element--59 {
  grid-column-start: 5;
  grid-row-start: 9;
}
.element--60 {
  grid-column-start: 6;
  grid-row-start: 9;
}
.element--61 {
  grid-column-start: 7;
  grid-row-start: 9;
}
.element--62 {
  grid-column-start: 8;
  grid-row-start: 9;
}
.element--63 {
  grid-column-start: 9;
  grid-row-start: 9;
}
.element--64 {
  grid-column-start: 10;
  grid-row-start: 9;
}
.element--65 {
  grid-column-start: 11;
  grid-row-start: 9;
}
.element--66 {
  grid-column-start: 12;
  grid-row-start: 9;
}
.element--67 {
  grid-column-start: 13;
  grid-row-start: 9;
}
.element--68 {
  grid-column-start: 14;
  grid-row-start: 9;
}
.element--69 {
  grid-column-start: 15;
  grid-row-start: 9;
}
.element--70 {
  grid-column-start: 16;
  grid-row-start: 9;
}
.element--71 {
  grid-column-start: 17;
  grid-row-start: 9;
}

.element--72 {
  grid-column-start: 4;
}

/* .element--89 {
  grid-column-start: 3;
  grid-row-start: 10;
} */
.element--90 {
  grid-column-start: 4;
  grid-row-start: 10;
}
.element--91 {
  grid-column-start: 5;
  grid-row-start: 10;
}
.element--92 {
  grid-column-start: 6;
  grid-row-start: 10;
}
.element--93 {
  grid-column-start: 7;
  grid-row-start: 10;
}
.element--94 {
  grid-column-start: 8;
  grid-row-start: 10;
}
.element--95 {
  grid-column-start: 9;
  grid-row-start: 10;
}
.element--96 {
  grid-column-start: 10;
  grid-row-start: 10;
}
.element--97 {
  grid-column-start: 11;
  grid-row-start: 10;
}
.element--98 {
  grid-column-start: 12;
  grid-row-start: 10;
}
.element--99 {
  grid-column-start: 13;
  grid-row-start: 10;
}
.element--100 {
  grid-column-start: 14;
  grid-row-start: 10;
}
.element--101 {
  grid-column-start: 15;
  grid-row-start: 10;
}
.element--102 {
  grid-column-start: 16;
  grid-row-start: 10;
}
.element--103 {
  grid-column-start: 17;
  grid-row-start: 10;
}

.element--104 {
  grid-column-start: 4;
}

:root {
  --borderRadius: 4px;
  --borderRadiusTop: 4px 4px 0 0;
  --borderRadiusBottom: 0 0 4px 4px;
  --borderRadiusLeft: 4px 0 0 4px;
  --borderRadiusRight: 0 4px 4px 0;

  --colorGray01: #99afcb;

  --colorAqua01: #9fd8ca;
  --colorAqua02: #52b6bd;

  --colorGreen01: #8cc63f;
  --colorGreen02: #396;

  --colorYellow01: #ffce00;

  --colorOrange01: #fe8002;

  --colorBlue01: #6495ed;
  --colorBlue02: #6b90ca;

  --colorRose01: #f95354;
  --colorRose02: #fa9d99;
}

.default-theme .solid,
.default-theme .nonmetal {
  background: #8cc63f;
  background: var(--colorGreen01);
}
.default-theme .gas,
.default-theme .noble.gas {
  background: #fa9d99;
  background: var(--colorRose02);
}
.default-theme .liquid,
.default-theme .alkali.metal {
  background: #f95354;
  background: var(--colorRose01);
}
.default-theme .alkaline.earth.metal {
  background: #fe8002;
  background: var(--colorOrange01);
}
.default-theme .metalloid {
  background: #396;
  background: var(--colorGreen02);
}
.default-theme .halogen {
  background: #ffce00;
  background: var(--colorYellow01);
}
.default-theme .metal {
  background: #6b90ca;
  background: var(--colorBlue02);
}
/* .default-theme .post-transition.metal {
  background: var(--colorBlue01);
} */
.default-theme .transition.metal {
  background: #99afcb;
  background: var(--colorGray01);
}

.default-theme .lanthanoid {
  background: #9fd8ca;
  background: var(--colorAqua01);
}
.default-theme .actinoid {
  background: #52b6bd;
  background: var(--colorAqua02);
}

.color.solid,
.color.nonmetal {
  background: transparent;
  color: #8cc63f;
  color: var(--colorGreen01);
}
.color.gas,
.color.noble.gas {
  background: transparent;
  color: #fa9d99;
  color: var(--colorRose02);
}
.color.liquid,
.color.alkali.metal {
  background: transparent;
  color: #f95354;
  color: var(--colorRose01);
}
.color.alkaline.earth.metal {
  background: transparent;
  color: #fe8002;
  color: var(--colorOrange01);
}
.color.metalloid {
  background: transparent;
  color: #396;
  color: var(--colorGreen02);
}
.color.halogen {
  background: transparent;
  color: #ffce00;
  color: var(--colorYellow01);
}
.color.metal {
  background: transparent;
  color: #6b90ca;
  color: var(--colorBlue02);
}
.color.transition.metal {
  background: transparent;
  color: #99afcb;
  color: var(--colorGray01);
}
.color.lanthanoid {
  background: transparent;
  color: #9fd8ca;
  color: var(--colorAqua01);
}
.color.actinoid {
  background: transparent;
  color: #52b6bd;
  color: var(--colorAqua02);
}
.color.post-transition.metal {
  background: transparent;
  color: #6495ed;
  color: var(--colorBlue01);
}

.blocks-theme .s-block {
  background: #f95354;
  background: var(--colorRose01);
}
.blocks-theme .f-block {
  background: #9fd8ca;
  background: var(--colorAqua01);
}
.blocks-theme .d-block {
  background: #99afcb;
  background: var(--colorGray01);
}
.blocks-theme .p-block {
  background: #ffce00;
  background: var(--colorYellow01);
}

.blocks-theme .color.s-block {
  background: none;
  color: #f95354;
  color: var(--colorRose01);
}
.blocks-theme .color.f-block {
  background: none;
  color: #9fd8ca;
  color: var(--colorAqua01);
}
.blocks-theme .color.d-block {
  background: none;
  color: #99afcb;
  color: var(--colorGray01);
}
.blocks-theme .color.p-block {
  background: none;
  color: #ffce00;
  color: var(--colorYellow01);
}

.table.cols {
  grid-column-gap: 2px;
}

.element--1,
.element--2 {
  border-radius: 4px 4px 0 0;
  border-radius: var(--borderRadiusTop);
}
.element--4,
.element--71 {
  border-radius: 0 4px 0 0;
}
.element--5,
.element--58 {
  border-radius: 4px 0 0 0;
}

.element--87,
.element--90 {
  border-radius: 0 0 0 4px;
}
.element--118,
.element--103 {
  border-radius: 0 0 4px 0;
}

.cols .element--1,
.cols .element--4,
.cols .element--21,
.cols .element--22,
.cols .element--23,
.cols .element--24,
.cols .element--25,
.cols .element--26,
.cols .element--27,
.cols .element--28,
.cols .element--29,
.cols .element--30,
.cols .element--5,
.cols .element--6,
.cols .element--7,
.cols .element--8,
.cols .element--9,
.cols .element--2,
.cols .element--58,
.cols .element--59,
.cols .element--60,
.cols .element--61,
.cols .element--62,
.cols .element--63,
.cols .element--64,
.cols .element--65,
.cols .element--66,
.cols .element--67,
.cols .element--68,
.cols .element--69,
.cols .element--70,
.cols .element--71 {
  border-radius: 4px 4px 0 0;
  border-radius: var(--borderRadiusTop);
}

.cols .element--87,
.cols .element--88,
.cols .element--89,
.cols .element--104,
.cols .element--105,
.cols .element--106,
.cols .element--106,
.cols .element--108,
.cols .element--109,
.cols .element--110,
.cols .element--111,
.cols .element--112,
.cols .element--113,
.cols .element--114,
.cols .element--115,
.cols .element--116,
.cols .element--117,
.cols .element--118,
.cols .element--90,
.cols .element--91,
.cols .element--92,
.cols .element--93,
.cols .element--94,
.cols .element--95,
.cols .element--96,
.cols .element--97,
.cols .element--98,
.cols .element--99,
.cols .element--100,
.cols .element--101,
.cols .element--102,
.cols .element--103 {
  border-radius: 0 0 4px 4px;
  border-radius: var(--borderRadiusBottom);
}

.table.rows {
  grid-row-gap: 4px;
}

.rows .element--1,
.rows .element--2 {
  border-radius: 4px;
  border-radius: var(--borderRadius);
}

.rows .element--3,
.rows .element--5,
.rows .element--11,
.rows .element--13,
.rows .element--19,
.rows .element--37,
.rows .element--55,
.rows .element--87,
.rows .element--58,
.rows .element--90 {
  border-radius: 4px 0 0 4px;
  border-radius: var(--borderRadiusLeft);
}

.rows .element--4,
.rows .element--10,
.rows .element--12,
.rows .element--18,
.rows .element--36,
.rows .element--54,
.rows .element--86,
.rows .element--118,
.rows .element--71,
.rows .element--103 {
  border-radius: 0 4px 4px 0;
  border-radius: var(--borderRadiusRight);
}

.table.cols.rows .element {
  border-radius: 4px;
  border-radius: var(--borderRadius);
}

.element {
  position: relative;
  font-family: Oswald, sans-serif;
  font-size: 12px;
  padding: 4px;
  min-width: 0;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  transition: all 0.14s linear;
  outline: 0;
  background: #333;
}

/* .element:hover {
  background: #eee;
  opacity: 0.8;
} */

.element:focus {
  /* background: #eee; */
  /* opacity: 0.8; */
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  z-index: 1000;
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* pointer-events: none; */
}

.br {
  height: 8px;
}

/* .element__prop[title='mass'] {
  border-bottom: 1px dotted;
} */

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* line-height: 1; */
}

.element__number {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 10px;
}

.element__symbol {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
}

.element__name {
  /* display: inline-block; */
  font-size: 12px;
  /* margin-bottom: 8px; */
}

.element__prop {
  display: inline-block;
  font-size: 12px;
  /* margin-top: 4px; */
  margin: 0;
  /* text-decoration: none; */

  transition: all 0.14s linear;
}
.element__prop:hover {
  color: #000;
}

.element__radius {
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #fff;
  opacity: 0.5;
}

.kelvins {
  width: 100%;
  /* font-family: Oswald, sans-serif; */
  font-size: 14px;
  color: #aaa;
  font-family: monospace;
  font-size: 13px;
}

.checkbox {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: none;
          flex: none;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
}

.checkbox__label {
  width: 100%;
  font-size: 14px;
  line-height: 32px;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 16px;
  transition: all 0.14s;
  color: #aaa;
  font-family: monospace;
}

.checkbox__label:hover {
  background: #eee;
}

.checkbox__input:checked ~ .checkbox__label {
  /* background: #000; */
  /* font-weight: bold; */
  color: #000;
  background: #eee;
}

/* .checkbox__input:checked ~ .checkbox__label::before {
  content: '– ';
} */

.input-button {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: none;
          flex: none;
}

.input-button__input {
  position: absolute;
  opacity: 0;
}

.input-button__label {
  color: #000;
  font-family: monospace;
}

.input-button__label {
  border-radius: 24px;
  border: 2px solid #fff;
  padding: 0 8px;
  cursor: pointer;
  margin-right: 4px;
  color: #fff;
  height: 28px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  opacity: 0.5;
}

.input-button__input:checked ~ .input-button__label {
  /* color: red; */
  /* color: #000; */
  /* border-color: #000; */
  opacity: 1;
}

