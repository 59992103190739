.input-button {
  position: relative;
  display: flex;
  align-items: center;
  flex: none;
}

.input-button__input {
  position: absolute;
  opacity: 0;
}

.input-button__label {
  color: #000;
  font-family: monospace;
}

.input-button__label {
  border-radius: 24px;
  border: 2px solid #fff;
  padding: 0 8px;
  cursor: pointer;
  margin-right: 4px;
  color: #fff;
  height: 28px;
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.input-button__input:checked ~ .input-button__label {
  /* color: red; */
  /* color: #000; */
  /* border-color: #000; */
  opacity: 1;
}
