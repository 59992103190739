.element {
  position: relative;
  font-family: Oswald, sans-serif;
  font-size: 12px;
  padding: 4px;
  min-width: 0;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  transition: all 0.14s linear;
  outline: 0;
  background: #333;
}

/* .element:hover {
  background: #eee;
  opacity: 0.8;
} */

.element:focus {
  /* background: #eee; */
  /* opacity: 0.8; */
  transform: scale(1.2);
  z-index: 1000;
  box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* pointer-events: none; */
}

.br {
  height: 8px;
}

/* .element__prop[title='mass'] {
  border-bottom: 1px dotted;
} */

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* line-height: 1; */
}

.element__number {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 10px;
}

.element__symbol {
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  font-family: Montserrat, sans-serif;
}

.element__name {
  /* display: inline-block; */
  font-size: 12px;
  /* margin-bottom: 8px; */
}

.element__prop {
  display: inline-block;
  font-size: 12px;
  /* margin-top: 4px; */
  margin: 0;
  /* text-decoration: none; */

  transition: all 0.14s linear;
}
.element__prop:hover {
  color: #000;
}

.element__radius {
  position: absolute;
  bottom: 4px;
  right: 4px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #fff;
  opacity: 0.5;
}
