:root {
  --borderRadius: 4px;
  --borderRadiusTop: 4px 4px 0 0;
  --borderRadiusBottom: 0 0 4px 4px;
  --borderRadiusLeft: 4px 0 0 4px;
  --borderRadiusRight: 0 4px 4px 0;

  --colorGray01: #99afcb;

  --colorAqua01: #9fd8ca;
  --colorAqua02: #52b6bd;

  --colorGreen01: #8cc63f;
  --colorGreen02: #396;

  --colorYellow01: #ffce00;

  --colorOrange01: #fe8002;

  --colorBlue01: #6495ed;
  --colorBlue02: #6b90ca;

  --colorRose01: #f95354;
  --colorRose02: #fa9d99;
}

.default-theme .solid,
.default-theme .nonmetal {
  background: var(--colorGreen01);
}
.default-theme .gas,
.default-theme .noble.gas {
  background: var(--colorRose02);
}
.default-theme .liquid,
.default-theme .alkali.metal {
  background: var(--colorRose01);
}
.default-theme .alkaline.earth.metal {
  background: var(--colorOrange01);
}
.default-theme .metalloid {
  background: var(--colorGreen02);
}
.default-theme .halogen {
  background: var(--colorYellow01);
}
.default-theme .metal {
  background: var(--colorBlue02);
}
/* .default-theme .post-transition.metal {
  background: var(--colorBlue01);
} */
.default-theme .transition.metal {
  background: var(--colorGray01);
}

.default-theme .lanthanoid {
  background: var(--colorAqua01);
}
.default-theme .actinoid {
  background: var(--colorAqua02);
}

.color.solid,
.color.nonmetal {
  background: transparent;
  color: var(--colorGreen01);
}
.color.gas,
.color.noble.gas {
  background: transparent;
  color: var(--colorRose02);
}
.color.liquid,
.color.alkali.metal {
  background: transparent;
  color: var(--colorRose01);
}
.color.alkaline.earth.metal {
  background: transparent;
  color: var(--colorOrange01);
}
.color.metalloid {
  background: transparent;
  color: var(--colorGreen02);
}
.color.halogen {
  background: transparent;
  color: var(--colorYellow01);
}
.color.metal {
  background: transparent;
  color: var(--colorBlue02);
}
.color.transition.metal {
  background: transparent;
  color: var(--colorGray01);
}
.color.lanthanoid {
  background: transparent;
  color: var(--colorAqua01);
}
.color.actinoid {
  background: transparent;
  color: var(--colorAqua02);
}
.color.post-transition.metal {
  background: transparent;
  color: var(--colorBlue01);
}

.blocks-theme .s-block {
  background: var(--colorRose01);
}
.blocks-theme .f-block {
  background: var(--colorAqua01);
}
.blocks-theme .d-block {
  background: var(--colorGray01);
}
.blocks-theme .p-block {
  background: var(--colorYellow01);
}

.blocks-theme .color.s-block {
  background: none;
  color: var(--colorRose01);
}
.blocks-theme .color.f-block {
  background: none;
  color: var(--colorAqua01);
}
.blocks-theme .color.d-block {
  background: none;
  color: var(--colorGray01);
}
.blocks-theme .color.p-block {
  background: none;
  color: var(--colorYellow01);
}

.table.cols {
  grid-column-gap: 2px;
}

.element--1,
.element--2 {
  border-radius: var(--borderRadiusTop);
}
.element--4,
.element--71 {
  border-radius: 0 4px 0 0;
}
.element--5,
.element--58 {
  border-radius: 4px 0 0 0;
}

.element--87,
.element--90 {
  border-radius: 0 0 0 4px;
}
.element--118,
.element--103 {
  border-radius: 0 0 4px 0;
}

.cols .element--1,
.cols .element--4,
.cols .element--21,
.cols .element--22,
.cols .element--23,
.cols .element--24,
.cols .element--25,
.cols .element--26,
.cols .element--27,
.cols .element--28,
.cols .element--29,
.cols .element--30,
.cols .element--5,
.cols .element--6,
.cols .element--7,
.cols .element--8,
.cols .element--9,
.cols .element--2,
.cols .element--58,
.cols .element--59,
.cols .element--60,
.cols .element--61,
.cols .element--62,
.cols .element--63,
.cols .element--64,
.cols .element--65,
.cols .element--66,
.cols .element--67,
.cols .element--68,
.cols .element--69,
.cols .element--70,
.cols .element--71 {
  border-radius: var(--borderRadiusTop);
}

.cols .element--87,
.cols .element--88,
.cols .element--89,
.cols .element--104,
.cols .element--105,
.cols .element--106,
.cols .element--106,
.cols .element--108,
.cols .element--109,
.cols .element--110,
.cols .element--111,
.cols .element--112,
.cols .element--113,
.cols .element--114,
.cols .element--115,
.cols .element--116,
.cols .element--117,
.cols .element--118,
.cols .element--90,
.cols .element--91,
.cols .element--92,
.cols .element--93,
.cols .element--94,
.cols .element--95,
.cols .element--96,
.cols .element--97,
.cols .element--98,
.cols .element--99,
.cols .element--100,
.cols .element--101,
.cols .element--102,
.cols .element--103 {
  border-radius: var(--borderRadiusBottom);
}

.table.rows {
  grid-row-gap: 4px;
}

.rows .element--1,
.rows .element--2 {
  border-radius: var(--borderRadius);
}

.rows .element--3,
.rows .element--5,
.rows .element--11,
.rows .element--13,
.rows .element--19,
.rows .element--37,
.rows .element--55,
.rows .element--87,
.rows .element--58,
.rows .element--90 {
  border-radius: var(--borderRadiusLeft);
}

.rows .element--4,
.rows .element--10,
.rows .element--12,
.rows .element--18,
.rows .element--36,
.rows .element--54,
.rows .element--86,
.rows .element--118,
.rows .element--71,
.rows .element--103 {
  border-radius: var(--borderRadiusRight);
}

.table.cols.rows .element {
  border-radius: var(--borderRadius);
}
