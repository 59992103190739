.table {
  /* min-width: 3000px; */
  display: flex;
  flex-wrap: wrap;

  display: grid;
  /* grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto; */
  grid-template-columns: repeat(18, 1fr);
  /* grid-column-gap: 2px; */
  /* grid-row-gap: 4px; */
  /* grid-template-rows: 25% 100px auto; */

  /* padding-top: 80px; */
  font-family: Oswald, sans-serif;
}

.table-kelvins {
  grid-column-start: 14;
  grid-column-end: span 3;
  grid-row-start: 1;
  font-size: 14px;
  color: #aaa;
}

.table-period {
  color: #aaa;
  text-align: right;
  padding: 4px 8px;
}

.table-period--1 {
  grid-row-start: 1;
}
.table-period--2 {
  grid-row-start: 2;
}
.table-period--3 {
  grid-row-start: 3;
}
.table-period--4 {
  grid-row-start: 4;
}
.table-period--5 {
  grid-row-start: 5;
}
.table-period--6 {
  grid-row-start: 6;
}
.table-period--7 {
  grid-row-start: 7;
}

.table-serie {
  font-size: 12px;
  color: #aaa;
  padding: 4px;
}

.table-align--right {
  text-align: right;
}

.element--1 {
  grid-column-start: 1;
}
.element--2 {
  grid-column-start: 18;
}
.element--5 {
  grid-column-start: 13;
}
.element--13 {
  grid-column-start: 13;
}
.element--21 {
  grid-column-start: 3;
}
.element--39 {
  grid-column-start: 3;
}

/* .element--57 {
  grid-column-start: 3;
  grid-row-start: 9;
} */
.element--58 {
  grid-column-start: 4;
  grid-row-start: 9;
}
.element--59 {
  grid-column-start: 5;
  grid-row-start: 9;
}
.element--60 {
  grid-column-start: 6;
  grid-row-start: 9;
}
.element--61 {
  grid-column-start: 7;
  grid-row-start: 9;
}
.element--62 {
  grid-column-start: 8;
  grid-row-start: 9;
}
.element--63 {
  grid-column-start: 9;
  grid-row-start: 9;
}
.element--64 {
  grid-column-start: 10;
  grid-row-start: 9;
}
.element--65 {
  grid-column-start: 11;
  grid-row-start: 9;
}
.element--66 {
  grid-column-start: 12;
  grid-row-start: 9;
}
.element--67 {
  grid-column-start: 13;
  grid-row-start: 9;
}
.element--68 {
  grid-column-start: 14;
  grid-row-start: 9;
}
.element--69 {
  grid-column-start: 15;
  grid-row-start: 9;
}
.element--70 {
  grid-column-start: 16;
  grid-row-start: 9;
}
.element--71 {
  grid-column-start: 17;
  grid-row-start: 9;
}

.element--72 {
  grid-column-start: 4;
}

/* .element--89 {
  grid-column-start: 3;
  grid-row-start: 10;
} */
.element--90 {
  grid-column-start: 4;
  grid-row-start: 10;
}
.element--91 {
  grid-column-start: 5;
  grid-row-start: 10;
}
.element--92 {
  grid-column-start: 6;
  grid-row-start: 10;
}
.element--93 {
  grid-column-start: 7;
  grid-row-start: 10;
}
.element--94 {
  grid-column-start: 8;
  grid-row-start: 10;
}
.element--95 {
  grid-column-start: 9;
  grid-row-start: 10;
}
.element--96 {
  grid-column-start: 10;
  grid-row-start: 10;
}
.element--97 {
  grid-column-start: 11;
  grid-row-start: 10;
}
.element--98 {
  grid-column-start: 12;
  grid-row-start: 10;
}
.element--99 {
  grid-column-start: 13;
  grid-row-start: 10;
}
.element--100 {
  grid-column-start: 14;
  grid-row-start: 10;
}
.element--101 {
  grid-column-start: 15;
  grid-row-start: 10;
}
.element--102 {
  grid-column-start: 16;
  grid-row-start: 10;
}
.element--103 {
  grid-column-start: 17;
  grid-row-start: 10;
}

.element--104 {
  grid-column-start: 4;
}
