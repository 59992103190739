.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  flex: none;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
}

.checkbox__label {
  width: 100%;
  font-size: 14px;
  line-height: 32px;
  vertical-align: middle;
  cursor: pointer;
  padding: 0 16px;
  transition: all 0.14s;
  color: #aaa;
  font-family: monospace;
}

.checkbox__label:hover {
  background: #eee;
}

.checkbox__input:checked ~ .checkbox__label {
  /* background: #000; */
  /* font-weight: bold; */
  color: #000;
  background: #eee;
}

/* .checkbox__input:checked ~ .checkbox__label::before {
  content: '– ';
} */
